import {
  AimOutlined, ClusterOutlined,
  FolderOutlined,
  LayoutOutlined,
  MailOutlined,
  SettingOutlined,
  TeamOutlined
} from "@ant-design/icons";
import { Link, useRouterState } from "@tanstack/react-router";
import { Menu } from "antd";
import { useSpaceContext } from "lib/context/SpaceContext";
import { useTr } from "lib/i18n/useTr";
import { FC } from "react";
import { ProjectMenuContainer } from "./styled";

const ProjectNavbar: FC = () => {
  const { T } = useTr();
  const router = useRouterState();
  const { data: { uid } } = useSpaceContext();

  const items = [
    {
      label: <Link to="/s/$id/home" params={{ id: uid }}>
        {T("menuItems.Home")}
      </Link>,
      key: "home",
      icon: <LayoutOutlined />,
    },
    {
      label: <Link to="/s/$id/deliverables" params={{ id: uid }}>
        {T("menuItems.Deliverables")}
      </Link>,
      key: "deliverables",
      icon: <AimOutlined />,
    },
    {
      label: <Link to="/s/$id/documents" params={{ id: uid }}>
        {T("menuItems.Documents")}
      </Link>,
      key: "documents",
      icon: <FolderOutlined />,
    },
    {
      label: <Link to="/s/$id/threads" params={{ id: uid }}>
        {T("menuItems.Correspondence")}
      </Link>,
      key: "threads",
      icon: <MailOutlined />,
    },
    // {
    //   label: <Link to="/s/$id/rules" params={{id: uid}}>{T("menuItems.Rules")}</Link>,
    //   key: "rules",
    //   icon: <ThunderboltOutlined />,
    // },
    {
      label: <Link to="/s/$id/members" params={{ id: uid }}>
        {T("menuItems.Members")}
      </Link>,
      key: "members",
      icon: <TeamOutlined />,
    },
    {
      label: <Link to="/s/$id/companies" params={{ id: uid }}>
        {T("menuItems.Companies")}
      </Link>,
      key: "companies",
      icon: <ClusterOutlined />,
    },
    {
      label: <Link to="/s/$id/settings" params={{ id: uid }}>
        {T("menuItems.Settings")}
      </Link>,
      key: "settings",
      icon: <SettingOutlined />
    },
  ];

  const currentKeys = items
    .filter(item => router.location.pathname.includes(String(item.key)))
    .map(item => item.key);

  return (
    <ProjectMenuContainer>
      <Menu
        selectedKeys={currentKeys}
        mode="horizontal"
        items={items}
        style={{ padding: "0 34px" }}
      />
    </ProjectMenuContainer>
  );
};

export default ProjectNavbar;
