let backendUrl: string;
let websocketUrl: string;

const host: string = import.meta.env.VITE_ATSK_BACKEND_URL || "";

// Local debug support
if (host.includes("localhost")) {
  backendUrl = `http://${host}/`;
  websocketUrl = `ws://${host}`;
} else {
  backendUrl = `https://${host}/`;
  websocketUrl = `wss://${host}`;
}

const configuration = {
  app: {
    debugEnv: import.meta.env.VITE_APP_DEBUG_ENV,
    isDevelopment: import.meta.env.DEV,
    isProduction: import.meta.env.PROD,
    supportEmail: "help@airtasks.com",
    title: import.meta.env.VITE_APP_TITLE || "Airtasks",
    nameLong: import.meta.env.VITE_APP_NAME || "Airtasks X",
    version: import.meta.env.VITE_APP_VERSION || new Date().getFullYear().toString(),
    hash: import.meta.env.VITE_APP_HASH,
  },
  links: {
    website: "https://airtasks.com",
    privacyPolicy: "https://airtasks.com/legal/privacy-policy",
    termsOfService: "https://airtasks.com/legal/terms-of-service",
    canny: "http://feedback.airtasks.com",
    documentation: "https://airtasks.notion.site",
    supportEmail: "help@airtasks.com",
  },
  backend: {
    url: backendUrl,
    ws: websocketUrl
  },
  sentry: {
    dsn: import.meta.env.VITE_SENTRY_DSN,
    proxy: backendUrl + "/application/proxy/sentry",
  },
  logRocket: {
    appId: import.meta.env.VITE_LOGROCKET_APP_ID || "",
    proxy: backendUrl + "/application/proxy/logrocket"
  },
  canny: {
    appId: import.meta.env.VITE_CANNY_APP_ID || "",
    privateKey: import.meta.env.VITE_CANNY_PRIVATE_KEY || "",
    proxy: backendUrl + "/application/proxy/canny",
  },
  algolia: {
    appId: import.meta.env.VITE_ALGOLIA_APP_ID || "",
    apiKey: import.meta.env.VITE_ALGOLIA_API_KEY || "",
  },
  apryse: {
    licenseKey: import.meta.env.VITE_APRYSE_KEY || "",
  },
};

export default configuration;
