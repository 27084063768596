import { MoonOutlined, SunOutlined } from "@ant-design/icons";
import { Segmented } from "antd";
import { LocalStorageKeys } from "lib/utils/localStorage";
import { FC } from "react";
import { TernaryDarkMode, useTernaryDarkMode } from "usehooks-ts";

const ThemeSwitch: FC = () => {
  const {
    isDarkMode,
    setTernaryDarkMode,
  } = useTernaryDarkMode({ localStorageKey: LocalStorageKeys.Theme });
  const segmentValue = isDarkMode ? "dark" : "light";

  return (
    <Segmented
      value={segmentValue}
      onChange={(value => setTernaryDarkMode(value as TernaryDarkMode))}
      options={[
        { value: "light", icon: <SunOutlined /> },
        { value: "dark", icon: <MoonOutlined /> },
      ]}
    />
  );
};

export default ThemeSwitch;
