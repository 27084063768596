import { getRouteApi, Outlet } from "@tanstack/react-router";
import { Empty } from "antd";
import AppLayout from "components/AppLayout";
import LoadingSkeleton from "components/LoadingSkeleton";
import ProjectNavbar from "components/ProjectNavbar";
import { SpaceProvider } from "lib/context/SpaceContext";
import { FC } from "react";
import { useGetSpaceQuery } from "redux/services/spacesApi";

const LayoutSpace: FC = () => {
  const route = getRouteApi("/s/$id");
  const { id }: { id: string } = route.useParams();
  const { data, isLoading, isSuccess, error } = useGetSpaceQuery(id);

  // TODO: @sergey style loading and error states
  return (
    <AppLayout showNavbar>
      {isLoading &&
        <LoadingSkeleton />
      }
      {error &&
        // TODO: @boris redirect to "/"
        <Empty description={JSON.stringify(error)} />
      }
      {isSuccess && data &&
        <SpaceProvider data={data}>
          <ProjectNavbar />
          <Outlet />
        </SpaceProvider>
      }
    </AppLayout>
  );
};

export default LayoutSpace;
