import { FC, PropsWithChildren } from "react";
import { LabelContainer } from "./styled";

const OptionLabel: FC<PropsWithChildren> = (props) => {
  return (
    <LabelContainer>{props.children}</LabelContainer>
  );
};

export default OptionLabel;
