import { ISpace, ISpaceCompany, ISpaceListItem, ISpaceMember, ISpaceTeam } from "types/space";
import { rootApi } from "./root";
import { IEntityId } from "types/entity";
import { JSendResponse } from "types/jsend";
import { validateJSendResponse } from "redux/helpers";
import { MemberInvitesTableItem } from "pages/Participants/Members/Invite/Table/lib";

export const spacesApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getSpaces: builder.query<Array<ISpaceListItem>, void>({
      query: () => ({
        url: "v2/spaces",
        method: "GET",
      }),
      providesTags: ["spacesList"],
    }),
    getSpace: builder.query<ISpace, IEntityId>({
      query: (id) => ({
        url: `v2/spaces/${id}`,
        method: "GET",
      }),
      providesTags: ["spaceDetails"],
    }),
    updateSpace: builder.mutation<ISpace, Partial<ISpace> & Pick<ISpace, "id">>({
      query: ({ id, ...data }) => ({
        url: `v2/spaces/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["spaceDetails"],
    }),
    addMembers: builder.mutation<JSendResponse, { spaceId: IEntityId, members: Array<MemberInvitesTableItem> }>({
      query: ({ spaceId, members }) => ({
        url: `v2/spaces/${spaceId}/members`,
        method: "POST",
        body: { members },
      }),
      invalidatesTags: ["spaceDetails"],
      transformResponse: validateJSendResponse,
    }),
    updateMember: builder.mutation<JSendResponse, { spaceId: IEntityId, memberId: IEntityId, data: Partial<ISpaceMember> }>({
      query: ({ spaceId, memberId, data }) => ({
        url: `v2/spaces/${spaceId}/members/${memberId}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["spaceDetails"],
      transformResponse: validateJSendResponse,
    }),
    deleteMember: builder.mutation<JSendResponse, { spaceId: IEntityId, memberId: IEntityId }>({
      query: ({ spaceId, memberId }) => ({
        url: `v2/spaces/${spaceId}/members/${memberId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["spaceDetails"],
      transformResponse: validateJSendResponse,
    }),
    createCompany: builder.mutation<JSendResponse, Partial<ISpaceCompany> & { spaceId: IEntityId }>({
      query: ({ spaceId, ...data }) => ({
        url: `v2/spaces/${spaceId}/companies`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["spaceDetails"],
      transformResponse: validateJSendResponse,
    }),
    updateCompany: builder.mutation<JSendResponse, Partial<ISpaceCompany> & { spaceId: IEntityId, companyId: IEntityId }>({
      query: ({ spaceId, companyId, ...data }) => ({
        url: `v2/spaces/${spaceId}/companies/${companyId}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["spaceDetails"],
      transformResponse: validateJSendResponse,
    }),
    deleteCompany: builder.mutation<JSendResponse, { spaceId: IEntityId, companyId: IEntityId }>({
      query: ({ spaceId, companyId }) => ({
        url: `v2/spaces/${spaceId}/companies/${companyId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["spaceDetails"],
      transformResponse: validateJSendResponse,
    }),
    createTeam: builder.mutation<JSendResponse, Partial<ISpaceTeam> & { spaceId: IEntityId }>({
      query: ({ spaceId, ...data }) => ({
        url: `v2/spaces/${spaceId}/teams`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["spaceDetails"],
      transformResponse: validateJSendResponse,
    }),
    updateTeam: builder.mutation<JSendResponse, Partial<ISpaceTeam> & { spaceId: IEntityId, teamId: IEntityId }>({
      query: ({ spaceId, teamId, ...data }) => ({
        url: `v2/spaces/${spaceId}/teams/${teamId}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["spaceDetails"],
      transformResponse: validateJSendResponse,
    }),
    deleteTeam: builder.mutation<JSendResponse, { spaceId: IEntityId, teamId: IEntityId }>({
      query: ({ spaceId, teamId }) => ({
        url: `v2/spaces/${spaceId}/teams/${teamId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["spaceDetails"],
      transformResponse: validateJSendResponse,
    }),
  }),
});

export const {
  useGetSpacesQuery, useLazyGetSpacesQuery,
  useGetSpaceQuery, useLazyGetSpaceQuery,
  useUpdateSpaceMutation,
  useAddMembersMutation,
  useUpdateMemberMutation,
  useDeleteMemberMutation,
  useCreateCompanyMutation,
  useUpdateCompanyMutation,
  useDeleteCompanyMutation,
  useCreateTeamMutation,
  useUpdateTeamMutation,
  useDeleteTeamMutation,
} = spacesApi;
