import { NavigateOptions, useNavigate } from "@tanstack/react-router";
import { FC, useEffect } from "react";

const Redirect: FC<NavigateOptions> = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(props);
  }, [navigate, props]);

  return (null);
};

export default Redirect;
