import { AnyRouter } from "@tanstack/react-router";
import configuration from "configuration";
import { FC, lazy, Suspense } from "react";

/**
 * @link https://tanstack.com/router/latest/docs/framework/react/devtools#only-importing-and-using-devtools-in-development
 */

interface RouterDevToolsProps {
  router?: AnyRouter;
}

const RouterDevTools: FC<RouterDevToolsProps> = (props) => {
  const { router } = props;

  const TanStackRouterDevtools =
    configuration.app.isProduction
      ? () => null // Render nothing in production
      : lazy(() =>
        // Lazy load in development
        import("@tanstack/router-devtools").then((res) => ({
          default: res.TanStackRouterDevtools,
          // For Embedded Mode
          // default: res.TanStackRouterDevtoolsPanel
        })),
      );

  return (
    <Suspense>
      <TanStackRouterDevtools router={router} />
    </Suspense>
  );
};

export default RouterDevTools;
