import { configureStore } from "@reduxjs/toolkit";
import { QueryStatus, setupListeners } from "@reduxjs/toolkit/query/react";
import { useDispatch, useSelector } from "react-redux";
import { rootApi } from "./services/root";

export const store = configureStore({
  reducer: {
    [rootApi.reducerPath]: rootApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      rootApi.middleware,
    ]),
});

setupListeners(store.dispatch);

// Define API endpoints type
export type EndpointName = keyof typeof rootApi.endpoints;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();

// Displaying global background fetching loading state
export const useIsPending = (ignoreQueries: Array<EndpointName> = []) => {
  const isPending = useAppSelector(state =>
    Object.values(state.api.queries).some(
      (query) =>
        query?.endpointName &&
        !ignoreQueries.includes(query.endpointName as EndpointName) &&
        query?.status === QueryStatus.pending
    ));

  return isPending;
};

export const useIsLoading = (selectTags: Array<EndpointName> = []) => {
  const isLoading = useAppSelector(state =>
    Object.values(state.api.queries).some(
      (query) =>
        query?.endpointName &&
        selectTags.includes(query.endpointName as EndpointName) &&
        query?.status === QueryStatus.pending
    ));

  return isLoading;
};
