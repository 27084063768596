import { Outlet } from "@tanstack/react-router";
import AppLayout from "components/AppLayout";
import { AtskScrollable } from "components/AppLayout/styled";
import { FC } from "react";

const LayoutUser: FC = () => {

  return (
    <AppLayout showNavbar>
      <AtskScrollable>
        <Outlet />
      </AtskScrollable>
    </AppLayout>
  );
};

export default LayoutUser;
