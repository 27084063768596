import { AppLocaleInterface } from "./en";

const fr: AppLocaleInterface = {
  formItems: {
    buttonSignIn: "Se connecter",
    buttonSignUp: "S'inscrire",
    labelFirstName: "Prénom",
    labelLastName: "Nom de famille",
    labelEmail: "Email",
    labelWorkEmail: "Email professionnel",
    labelPassword: "Mot de passe"
  },
  menuItems: {
    Home: "Accueil",
    Documents: "Documents",
    Correspondence: "Threads",
    Deliverables: "Livrables",
    Rules: "Rules",
    Lists: "Listes",
    Reviews: "Critiques",
    Models: "Modèles",
    Participants: "Participants",
    Members: "Members",
    Companies: "Companies",
    Settings: "Paramètres", // Verified
    Preferences: "Preferences",
    AdminConsole: "Admin console",
    LogOut: "Déconnexion",
  },
  entities: {
    Organization: "Organization",
    Project: "Project",
    Profile: "Profile",
    User: {
      FirstName: "Prénom", // Verified - ChatGPT
      LastName: "Nom de famille", // Verified - ChatGPT
      Email: "E-mail", // Verified - Asana
      JobTitle: "Poste", // Verified - Asana
      Department: "Service ou équipe", // Verified - Asana
      About: "À mon sujet", // Verified - Asana
      Language: "Langue"  // Verified - Asana
    }
  },
  pages: {
    UserSettings: {
      Settings: "Paramètres", // Verified - Asana
      General: "Général", // Verified - ChatGPT
      Professional: "Professionnel", // Verified - ChatGPT
      Regional: "Régional", // Verified - ChatGPT
      About: {
        About: "À propos", // Verified - ChatGPT
        Version: "Version", // Verified - ChatGPT
        Website: "Site web", // Verified - ChatGPT
        PrivacyPolicy: "Politique de confidentialité", // Verified - ChatGPT
        TermsOfService: "Conditions d'utilisation", // Verified - ChatGPT
        Support: "Support", // Verified - ChatGPT
        Copyright: "Droits d'auteur" // Verified - ChatGPT
      }
    }
  }
};

export default fr;
