import { notification } from "antd";
import { setStoredAuthToken } from "lib/utils/auth";
import { validateBaseQueryInvalidateTags, validateBaseQueryStatus } from "redux/helpers";
import { IUser, IUserAuth, IUserAuthPayload, IUserRegisterPayload } from "types/user";
import { rootApi } from "./root";

export const userApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<IUser, IUserAuthPayload>({
      query: (credentials) => ({
        url: "auth/local",
        method: "POST",
        body: credentials,
        validateStatus: validateBaseQueryStatus,
      }),
      transformResponse(response: IUserAuth) {
        setStoredAuthToken(response.jwt);
        return response.user;
      },
      invalidatesTags: (result, error, args) => {
        const isSuccess = validateBaseQueryInvalidateTags(result, error, args);
        return isSuccess ? ["user"] : [];
      },
    }),
    register: builder.mutation<IUser, IUserRegisterPayload>({
      query: (credentials) => ({
        url: "auth/local/register",
        method: "POST",
        body: credentials,
        validateStatus: validateBaseQueryStatus,
      }),
      transformResponse(response: IUserAuth) {
        setStoredAuthToken(response.jwt);
        return response.user;
      },
      invalidatesTags: (result, error, args) => {
        const isSuccess = validateBaseQueryInvalidateTags(result, error, args);
        return isSuccess ? ["user"] : [];
      },
    }),
    getProfile: builder.query<IUser, void>({
      query: () => ({
        url: "users/self",
        method: "GET",
        validateStatus: validateBaseQueryStatus,
      }),
      providesTags: ["user"],
      transformErrorResponse(baseQueryReturnValue) {
        if (baseQueryReturnValue.status === "FETCH_ERROR") {
          notification.open({
            message: "Seems like you're offline",
            type: "warning",
            description:
              "We couldn't reach the server. Please check your internet connection and try again.",
          });
        }

        if (baseQueryReturnValue.status === 401) {
          notification.open({
            message: "Unauthorized",
            type: "error",
            description: "Please login to continue.",
          });
          setStoredAuthToken(null);
        }

        if (baseQueryReturnValue.status === 403) {
          setStoredAuthToken(null);
        }

        return null;
      },
    }),
    updateProfile: builder.mutation<IUser, Partial<IUser>>({
      query: (data) => ({
        url: "users/self",
        method: "PUT",
        body: data,
      }),
      async onQueryStarted({ ...patch }, { dispatch }) {
        dispatch(
          userApi.util.updateQueryData("getProfile", undefined, (draft) => {
            Object.assign(draft, patch);
          })
        );
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useRegisterMutation,
  useGetProfileQuery, useLazyGetProfileQuery,
  useUpdateProfileMutation,
} = userApi;
