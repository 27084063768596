import { Skeleton, SkeletonProps } from "antd";
import { FC } from "react";

const LoadingSkeleton: FC<SkeletonProps> = (props) => {
  return (
    <div style={{ padding: 50 }}>
      <Skeleton
        active
        {...props}
      />
    </div>
  );
};

export default LoadingSkeleton;
