import { Flex, Layout } from "antd";
import { FC, PropsWithChildren } from "react";

const LayoutPageWrapper: FC<PropsWithChildren> = (props) => {
  return (
    <Layout>
      <Flex
        align="center"
        justify="center"
        style={{ width: "100vw", height: "100vh" }}
      >
        {props.children}
      </Flex>
    </Layout>
  );
};

export default LayoutPageWrapper;
