import styled from "@emotion/styled";

export const AtskAppRoot = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  overflow-y: auto;
`;

export const AtskFullPage = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  min-height: 560px;
`;

export const AtskGlobalNavbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 60px;
  width: 100%;
  min-width: 320px;
  column-gap: 12px;
  background-color: #141414;
  padding-inline: 20px;
`;

export const AtskMain = styled.div`
  display: flex;
  min-height: 1px;
  flex: 1 1 0;
`;

export const AtskSidebar = styled.div`
  width: 0;
`;

export const AtskMainContent = styled(AtskMain)`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  min-width: 920px;
  @media screen and (max-width: 575px) {
    min-width: max(100vw, 320px)
  }
  @media screen and (min-width: 576px) and (max-width: 919px) {
    min-width: 100vw
  }
  @media screen and (min-width: 920px) {
    min-width: 920px
  }
`;

export const AtskScrollable = styled.div`
  width: 100%;
  min-height: 1px;
  overflow-y: auto;
`;
