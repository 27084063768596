import OptionLabel from "components/OptionLabel";

export enum AppLocaleCode {
  English = "en",
  French = "fr",
}

export const AppLocale = {
  English: {
    code: AppLocaleCode.English,
    title: "English",
  },
  French: {
    code: AppLocaleCode.French,
    title: "Français",
  },
};

export const AppLocaleOptions = [
  {
    label: AppLocale.English.title,
    title: AppLocale.English.title,
    value: AppLocale.English.code,
  },
  {
    label: <>{AppLocale.French.title} <OptionLabel>Beta</OptionLabel></>,
    title: AppLocale.French.title,
    value: AppLocale.French.code,
  },
];
