import styled from "@emotion/styled";
import { Link } from "@tanstack/react-router";
import { Card, Divider, Layout } from "antd";

export const AuthPageContainer = styled(Layout)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  padding-top: 10px;
`;

export const AuthFormContainer = styled.div`
  width: 425px;
  padding: 5px;
  @media (max-width: 425px) {
    width: 100%;
  }
`;

export const AuthFormCard = styled(Card)`
  padding: 20px;
  @media (max-width: 575px) {
    border: none;
    background-color: transparent !important;
  }
`;

export const AuthLogoContainer = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const AuthLogo = styled.img`
  width: 80px;
  height: 80px;
`;

export const AuthDivider = styled(Divider)`
  margin: 25px 0 !important;
`;

export const AuthGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
`;

export const AuthSubmit = styled.div`
  text-align: center;
  margin-top: 20px;
`;

export const AuthTip = styled(Link)`
  display: block;
  text-align: center;
`;
