import { createFileRoute, redirect } from "@tanstack/react-router";

// TODO: check the documentations, seems like an ugly hack (why can't we get params by parsing `$id` instead)
interface RouteParams {
  id: string;
}

export const Route = createFileRoute("/account/$id/")({
  loader: ({ params }: { params: RouteParams }) => {
    throw redirect({
      to: "/account/$id/projects",
      params: { id: params.id },
    });
  }
});
