import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import LayoutPageWrapper from "components/LayoutPageWrapper";
import { FC } from "react";

const LoaderPage: FC = () => {
  return (
    <LayoutPageWrapper>
      <Spin indicator={<LoadingOutlined spin />} size="large" />
    </LayoutPageWrapper>
  );
};

export default LoaderPage;
