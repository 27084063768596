import * as Sentry from "@sentry/react";
import { message } from "antd";
import configuration from "configuration";
import { getUserFullName } from "lib/utils/user";
import { IUser } from "types/user";
import { Primitive } from "types/utility";

export function initSentry() {
  Sentry.init({
    dsn: configuration.sentry.dsn,
    debug: configuration.app.isDevelopment,
    release: configuration.app.version,
    environment: configuration.app.debugEnv,
    // TODO: set up  a proxy
    // tunnel: configuration.sentry.proxy
    integrations: [
      Sentry.captureConsoleIntegration(),
      Sentry.extraErrorDataIntegration(),
    ],
  });
}

export function captureSentryException(e: unknown, showUiMessage?: boolean, uiMessageText?: string) {
  Sentry.captureException(e);

  if (showUiMessage && (uiMessageText?.length || e)) {
    message.error(uiMessageText ?? e as string);
  }
}

export function setSentryScopeTag(key: string, value: Primitive) {
  Sentry.getCurrentScope().setTag(key, value);
}

export function setSentryUser(user: IUser | null) {
  if (user) {
    Sentry.setUser({
      id: String(user.id),
      username: getUserFullName(user),
      email: user.email,
      locale: user.locale,
      version: user.version,
    });
  }
}

export const SentryErrorBoundary = Sentry.ErrorBoundary;
