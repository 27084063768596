import { IUser } from "types/user";
import { RegEx } from "./regexp";

export const getUserFullName = (user?: IUser | null) => {
  return (user?.firstName)
    ? user?.firstName + " " + user?.lastName
    : user?.email;
};

export const getUserFirstName = (user?: IUser | null) => {
  return (user?.firstName)
    ? user?.firstName
    : user?.email;
};

export const getUserInitials = (user?: IUser | null) => {
  if (user?.firstName) {
    const letters = user.firstName[0];
    return letters.toUpperCase();
  } else if (user?.email) {
    return user.email.slice(0, 1).toUpperCase();
  }
};

export const validateEmail = (email: string) => {
  return RegEx.Email.test(email);
};

export const cleanEmail = (email: string) => {
  return email.toLowerCase().trim();
};
