import { TypedUseQueryStateResult } from "@reduxjs/toolkit/query/react";
import React, { FC, PropsWithChildren, useContext } from "react";
import { IAccount } from "types/account";

interface AccountContextProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: TypedUseQueryStateResult<IAccount, unknown, any>;
}

const AccountContext = React.createContext<AccountContextProps>({
  value: undefined!,
});

export const useAccountContext = () => useContext(AccountContext);

export const AccountProvider: FC<PropsWithChildren<AccountContextProps>> = (props) => {
  return (
    <AccountContext.Provider value={{ ...props }}>
      {props.children}
    </AccountContext.Provider>
  );
};
