import { CloseCircleOutlined, FileUnknownOutlined, SmileOutlined } from "@ant-design/icons";
import { Button, Result, Typography } from "antd";
import LayoutPageWrapper from "components/LayoutPageWrapper";
import { FC } from "react";

const { Paragraph, Text } = Typography;

interface ErrorPageProps {
  type: "error" | "notFound";
  description?: string;
}

const ErrorPage: FC<ErrorPageProps> = (props) => {
  const { type } = props;

  const icon = (type === "error")
    ? <SmileOutlined />
    : <FileUnknownOutlined />;

  const handleOnNavigate = () => {
    window.location.href = "/";
  };

  return (
    <LayoutPageWrapper>
      <Result
        title="Submission Failed"
        subTitle="Please check and modify the following information before resubmitting."
        icon={icon}
        extra={[
          <Button type="primary" key="console" onClick={handleOnNavigate}>
            Reload the app
          </Button>,
        ]}
      >
        <div className="desc">
          <Paragraph>
            <Text
              strong
              style={{
                fontSize: 16,
              }}
            >
              The content you submitted has the following error:
            </Text>
          </Paragraph>
          <Paragraph>
            {props.description}
          </Paragraph>
          <Paragraph>
            <CloseCircleOutlined className="site-result-demo-error-icon" /> Your account has been
            frozen. <a>Thaw immediately &gt;</a>
          </Paragraph>
          <Paragraph>
            <CloseCircleOutlined className="site-result-demo-error-icon" /> Your account is not yet
            eligible to apply. <a>Apply Unlock &gt;</a>
          </Paragraph>
        </div>
      </Result>
    </LayoutPageWrapper>
  );
};

export default ErrorPage;
