import { createContext, FC, PropsWithChildren, useContext } from "react";
import { ISpace } from "types/space";

interface SpaceContextProps {
  data: ISpace;
}

interface SpaceProviderProps {
  data: ISpace;
}

const SpaceContext = createContext<SpaceContextProps>({
  data: undefined!,
});

export const useSpaceContext = () => useContext(SpaceContext);

export const SpaceProvider: FC<PropsWithChildren<SpaceProviderProps>> = (props) => {
  const { children, ...data } = props;
  return (
    <SpaceContext.Provider value={data}>
      {children}
    </SpaceContext.Provider>
  );
};
