import configuration from "configuration";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import { setSentryScopeTag } from "./sentry";
import { IUser } from "types/user";
import { getUserFullName } from "lib/utils/user";

export function initLogRocket() {
  LogRocket.init(configuration.logRocket.appId, {
    release: configuration.app.version,
    // TODO: set up  a proxy
    // serverURL: configuration.logRocket.proxy,
  });

  setupLogRocketReact(LogRocket);

  LogRocket.getSessionURL(sessionURL => setSentryScopeTag("sessionURL", sessionURL));
}

export function setLogRocketUser(user: IUser | null) {
  if (user) {
    LogRocket.identify({
      id: String(user.id),
      name: getUserFullName(user) || user.email,
      email: user.email,
      updated_at: String(user.updated_at),
      locale: String(user.locale),
      version: String(user.version),
    });
  }
}
