import { LocalStorageKeys } from "./localStorage";

export function getStoredAuthToken() {
  return localStorage.getItem(LocalStorageKeys.Jwt);
}

export function setStoredAuthToken(jwt: string | null) {
  if (jwt) {
    localStorage.setItem(LocalStorageKeys.Jwt, jwt);
  } else {
    localStorage.removeItem(LocalStorageKeys.Jwt);
  }
}

export function hasStoredAuthToken() {
  return !!localStorage.getItem(LocalStorageKeys.Jwt);
}
