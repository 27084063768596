const en = {
  formItems: {
    buttonSignIn: "Sign in",
    buttonSignUp: "Sign up",
    labelFirstName: "First name",
    labelLastName: "Last name",
    labelEmail: "Email",
    labelWorkEmail: "Work email",
    labelPassword: "Password",
  },
  menuItems: {
    Home: "Home",
    Documents: "Documents",
    Correspondence: "Threads",
    Deliverables: "Deliverables",
    Rules: "Rules",
    Lists: "Lists",
    Reviews: "Markups",
    Models: "Models",
    Participants: "Participants",
    Members: "Members",
    Companies: "Companies",
    Settings: "Settings",
    Preferences: "Preferences",
    AdminConsole: "Admin console",
    LogOut: "Log out",
  },
  entities: {
    Organization: "Organization",
    Project: "Project",
    Profile: "Profile",
    User: {
      FirstName: "First name",
      LastName: "Last name",
      Email: "Email",
      JobTitle: "Job title",
      Department: "Department or team",
      About: "About me",
      Language: "Language"
    }
  },
  pages: {
    UserSettings: {
      Settings: "Settings",
      General: "General",
      Professional: "Professional",
      Regional: "Regional",
      About: {
        About: "About",
        Version: "Version",
        Website: "Website",
        PrivacyPolicy: "Privacy policy",
        TermsOfService: "Terms of service",
        Support: "Support",
        Copyright: "Copyright"
      }
    }
  }
};

export type AppLocaleInterface = typeof en;

export default en;
