import { Outlet } from "@tanstack/react-router";
import { FC } from "react";
import { AuthFormCard, AuthFormContainer, AuthLogo, AuthLogoContainer, AuthPageContainer } from "layouts/Auth/styled";
import { theme } from "antd";
import AppLayout from "components/AppLayout";

const LayoutAuth: FC = () => {
  const { token } = theme.useToken();
  const { colorBgContainer } = token;

  return (
    <AppLayout>
      <AuthPageContainer>
        <AuthFormContainer>
          <AuthFormCard style={{ backgroundColor: colorBgContainer }}>
            <AuthLogoContainer>
              <AuthLogo alt="logo" src="/a-black.svg" />
            </AuthLogoContainer>
            <Outlet />
          </AuthFormCard>
        </AuthFormContainer>
      </AuthPageContainer>
    </AppLayout>
  );
};

export default LayoutAuth;
