import { FC, PropsWithChildren } from "react";
import { useTernaryDarkMode } from "usehooks-ts";
import { ConfigProvider, ConfigProviderProps, theme } from "antd";
import { LocalStorageKeys } from "lib/utils/localStorage";

export const AntConfigProvider: FC<PropsWithChildren<ConfigProviderProps>> = (props) => {
  const { isDarkMode } = useTernaryDarkMode({ localStorageKey: LocalStorageKeys.Theme });
  const algorithm = isDarkMode ? theme.darkAlgorithm : theme.defaultAlgorithm;

  return (
    <ConfigProvider
      theme={{
        algorithm,
        token: {
          "colorPrimary": "#2f54eb",
          "colorInfo": "#2f54eb"
        }
      }}
    >
      {props.children}
    </ConfigProvider>
  );
};
