import { FC, PropsWithChildren } from "react";
import { theme } from "antd";
import {
  AtskAppRoot,
  AtskFullPage,
  AtskGlobalNavbar,
  AtskMain,
  AtskMainContent,
  AtskSidebar
} from "./styled";
import AppLogo from "components/AppLogo";
import UserSettingsDropdown from "components/UserSettingsDropdown";

interface AppLayoutProps {
  showNavbar?: boolean;
}

const AppLayout: FC<PropsWithChildren<AppLayoutProps>> = (props) => {
  const { showNavbar = false, children } = props;
  const { token } = theme.useToken();

  return (
    <AtskAppRoot>
      <AtskFullPage>
        {showNavbar && (
          <AtskGlobalNavbar>
            <AppLogo />
            <UserSettingsDropdown />
          </AtskGlobalNavbar>
        )}
        <AtskMain style={{ backgroundColor: token.colorBgContainer }}>
          <AtskSidebar />
          <AtskMainContent>
            {children}
          </AtskMainContent>
        </AtskMain>
      </AtskFullPage>
    </AtskAppRoot>
  );
};

export default AppLayout;
