import { createRootRouteWithContext, Outlet } from "@tanstack/react-router";
import { IUser } from "types/user";

export interface RouterContext {
  user: IUser;
}

export const Route = createRootRouteWithContext<RouterContext>()({
  beforeLoad(ctx) {
    console.log("beforeLoad", ctx.context);
  },
  component: () => <Outlet />,
  onCatch(error, errorInfo) {
    console.error("onCatch", error, errorInfo);
  },
});
