import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import configuration from "configuration";
import { getStoredAuthToken } from "lib/utils/auth";

// initialize an empty api service that we'll inject endpoints into later as needed
export const rootApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: configuration.backend.url,
    prepareHeaders: (headers, api) => {
      if (["login", "register"].includes(api.endpoint)) {
        return headers;
      }

      const token = getStoredAuthToken();
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: [
    "user",
    "accountsList",
    "accountDetails",
    "spacesList",
    "spaceDetails",
  ],
  endpoints: () => ({}),
});
