import styled from "@emotion/styled";

export const LabelContainer = styled.span`
    padding: 2px 6px;
    border-radius: 4px;
    color: #37352FA5;
    background: #CECDCA7F;
    font-size: 9px;
    text-transform: uppercase;
    white-space: nowrap;
    transform: translateX(5px)
`;
