import { FileBaseRouteOptions, redirect } from "@tanstack/react-router";

export const routerAuthMiddleware: FileBaseRouteOptions["beforeLoad"] = (context) => {
  if (!context.context.user?.id) {
    throw redirect({ to: "/auth/login" });
  }
};

export const routerUnAuthMiddleware: FileBaseRouteOptions["beforeLoad"] = (context) => {
  if (context.context.user?.id) {
    throw redirect({ to: "/" });
  }
};
